@import "../app-core/mixins.scss";

.post-quote__inner {
  padding: 5px;
  background-color: #e1e1e1;
}

.post-quote__quotation {
  quotes: '\00AB' '\00BB';

  &:before {
    content: open-quote;
  }

  &:after {
    content: close-quote;
  }

  .post-quote--default & {
    position: relative;

    &:before,
    &:after {
      position: absolute;
      @include fontAccent();
      font-size: 3em;
      quotes: none;
    }

    &:before {
      content: '\201C';
      top: -.15em;
      right: -.15em;
    }

    &:after {
      content: '\201D';
      top: .05em;
      right: -.45em;
    }
  }
}
