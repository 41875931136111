@import "../app-core/fonts.scss";
@import "../app-core/mixins.scss";

.typo {
  @include fontBase;
  font-size: 8pt;
  line-height: 1.8em;
}

.typo-accent {
  @include fontAccent;
  font-size: 8pt;
}

// ================================= sizes
.typo--small {
  font-size: 6pt;
  font-variation-settings: "wght" 400, "SHAR" 0, "SERI" 0, "KASH" 5;
}

.typo-accent--small {
  @include fontAccent;
  font-size: 6pt;
  font-variation-settings: "wght" 300, "wdth" 75, "slnt" 0;
}

.typo-accent--xsmall {
  font-size: 5pt;
  font-variation-settings: "wght" 300, "wdth" 75, "slnt" 0;
}

.typo--large {
  font-size: 10pt;
  line-height: 1.7em;
}

.typo--latin {
  font-size: 0.85em;
}

// ================================= weights
.typo--weight-light {
  font-variation-settings: "wght" 200, "SHAR" 0, "SERI" 0, "KASH" 0;
}

.typo-accent--weight-light {
  font-variation-settings: "wght" 200, "wdth" 75, "slnt" 0;
}

.typo--weight-latin-light {
  font-variation-settings: "wght" 300;
}

.typo--weight-bold {
  @include bold('font-base');
}

.typo-accent--weight-bold {
  @include bold('font-accent');
}

// ================================= alignment
.typo--align-center {
  text-align: center;
}

// ================================= colors
.typo--color-dark-muted {
  opacity: .7;
  @include font-smoothing(false);
}

// ================================= headings

.h1 {
  font-size: 18pt;
  line-height: 1.4em;
  font-variation-settings: "wght" 700, "SHAR" 0, "SERI" 0, "KASH" 5;
}

.h2 {
  font-size: 14pt;
  line-height: 1.5em;
  font-variation-settings: "wght" 700, "SHAR" 0, "SERI" 0, "KASH" 5;
}

.h3 {
  font-size: 11pt;
  line-height: 1.5em;
  font-variation-settings: "wght" 650, "SHAR" 0, "SERI" 0, "KASH" 5;
}

.h4 {
  @include fontAccent;
  font-size: 12pt;
  line-height: 1.2em;
  font-variation-settings: "wght" 400, "wdth" 75, "slnt" 0;
}

strong {
  .typo & {
    @include bold('font-base');
  }

  .typo-accent & {
    @include bold('font-accent');
  }
}

.link {
  text-decoration: underline;
  @include bold('font-base');
}
