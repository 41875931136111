@import "./_includes/components/app-core/reset.scss";

@import "./_includes/components/typography/typography.print.scss";
@import "./_includes/components/app-header/app-header.print.scss";
@import "./_includes/components/app-footer/app-footer.print.scss";
@import "./_includes/components/offline-message/offline-message.print.scss";
@import "./_includes/components/offline-message/offline-message.print.scss";
@import "./_includes/components/grid-lines/grid-lines.print.scss";
@import "./_includes/components/promoted-slot/promoted-slot.print.scss";
@import "./_includes/components/featured-image/featured-image.print.scss";
@import "./_includes/components/post-series/post-series.print.scss";
@import "./_includes/components/offscreen-message/offscreen-message.print.scss";
@import "./_includes/components/svg-sprite/svg-sprite.print.scss";
@import "./_includes/components/post-image/post-image.print.scss";
@import "./_includes/components/blocks/blocks.print.scss";
@import "./_includes/components/image-slider/image-slider.print.scss";
@import "./_includes/components/comment-list/comment-list.print.scss";
@import "./_includes/components/post-aside/post-aside.print.scss";
@import "./_includes/components/embed-handler/embed-handler.print.scss";
@import "./_includes/components/post-quote/post-quote.print.scss";
@import "./_includes/components/highcharts/highcharts.print.scss";

@import "./pages/posts/entry.print.scss";

body {
  padding: 10px;
}

[data-native-share],
form[data-form] {
  display: none;
}

img {
  page-break-before: avoid;
  page-break-after: avoid;
  page-break-inside: avoid;
}

p {
  margin: 0;
}

.fixed-ratio {
  display: block;
  padding-bottom: 0 !important;
}

.fixed-ratio img {
  display: block;
  width: 100%;
  height: auto;
}
