.post__author-container img,
.post__featured-media svg,
.post__info-meta,
.post__social-share,
.post__meta {
  display: none;
}

.post__header,
.post__content {
  margin-right: auto;
  margin-left: auto;
}

.post__header {
  max-width: 800px;
}

.post__content {
  max-width: 500px;
  margin-top: 40px;
}

.post__page-break:not(:last-of-type) {
  page-break-after: always;
}

.post__page-break hr {
  border: none;
  border-bottom: 1px solid grey;
}
