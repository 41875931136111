.blocks__unit {
  margin-top: 10px;
}

.blocks__unit .h3 {
  margin-top: 30px;
}

.blocks a::after{
  content: " (" attr(href) ") ";
  direction: ltr;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
}
