.post-image {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-right: 0;
  margin-left: 0;
}

.post-image img {
  display: block;
  width: 100%;
  height: auto;
}
