.highcharts__actions {
  display: none;
}

.highcharts .table {
  position: relative;
  width: 100%;
  height: auto;
  clip: auto;
}

.highcharts .table .table__wrapper {
  max-width: none;
}

.highcharts .table__wrapper table {
  width: 100%;
  padding: 0.5rem;
  border-spacing: 0.5rem;
  border-collapse: collapse;
}

.highcharts .table__wrapper td,
.highcharts .table__wrapper th {
  padding: 0.5rem;
  border: 1px solid black;
}

.highcharts .highcharts-root {
  display: block;
  width: 100%;
  height: 100%;
}
