.slider {
  margin-top: 30px;
}

.slider img {
  display: block;
  width: 100%;
  height: auto;
}

.slider__slide + .slider__slide {
  margin-top: 15px;
}

.slider__hint {
  display: none;
}
