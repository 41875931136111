.app-header .logo--small,
.app-header .visually-hidden,
.app-header > *:not(.app-header__logo){
  display: none;
}

.app-header {
  max-width: 800px;

  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 30px;
  margin-left: auto;
  padding-bottom: 10px;

  border-bottom: 1px solid grey;
}

.app-header .logo--large {
  display: block;
  width: 70px;
  height: 15px;
  margin-right: auto;
  margin-left: auto;
  color: black;
}
